<template>
     <router-link
         :to="getWidget.link.url"
         class="explore__link"
         :class="[scrolled ? 'active':'']"
         v-if="getWidget && false"
         :target="getWidget.link.targetblank ? '_blank' : ''"
     >
      <div class="explore__link-icon">
        <svg width="81" height="85" viewBox="0 0 81 85" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M71.713 39.9522L0.128642 39.9522V44.9522L70.9043 44.9522L35.2964 80.56L38.832 84.0956L79.112 43.8156L80.8797 42.0478L79.112 40.28L38.832 1.81758e-05L35.2964 3.53555L71.713 39.9522Z" fill="black"/>
        </svg>
      </div>
      <div class="explore__link-text">
        <span>{{getWidget.subtitle}}</span>
      </div>
    </router-link>
</template>
<script setup>
import {computed, nextTick, onMounted, ref, watch} from "vue";
import {useMenuStore} from '@/store/pinia/menu';
import {get} from "lodash";
const store = useMenuStore();
let scrolled = ref(false);

// Define reactive data
const getWidget = computed(() => {
  return get(store, 'indx.widgets.explore-cityzen');
});

const headerBehaviour = () => {
  if (window.innerWidth < 1024) {
    document.querySelector('#app').addEventListener('scroll', e => {
      scrolled.value = e.target.scrollTop >= 200
    })
    return
  }
  setTimeout(() => {
    store.Loco?.on('scroll', func => {
      scrolled.value = func.scroll.y > window.innerHeight - 300
    });
  }, 1000)
}
onMounted(() => {
  headerBehaviour()
})
</script>
<style lang="scss" scoped>
  .explore__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 198px;
    z-index: 10;
    right: 0px;
    width: 140px;
    height: 160px;
    transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  }
  .explore__link:after {
    content:'';
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #fff;
    position: absolute;
    top: 0px;
    right: 0px;
    transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    transform: scaleY(0);
    transition-delay: .3s;
    transform-origin: top;
  }
  .explore__link.active:after {
    transform: scaleY(1);
    transition-delay: 0s;
  }
  .explore__link:hover:after {
    background: rgba(255,255,255,.8);
  }
  .explore__link-text span {
    font-size: 30px;
    line-height: 32px;
    transform: translateY(120%);
    transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    transition-delay: .1s;
    text-transform: uppercase;
  }
  .explore__link.active .explore__link-text span {
    transform: translateY(0%);
    transition-delay: .2s;
  }
  .explore__link-text {
    transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    overflow: hidden;
    display: flex;
  }
  .explore__link-icon {
    margin-bottom: 5px;
    transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    transform: translateX(-5px);
    opacity: 0;
  }
  .explore__link.active .explore__link-icon {
    transform: translateX(0px);
    opacity: 1;
    transition-delay: .1s;
  }
  .explore__link-icon svg {
    transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  }
  .explore__link:hover svg {
    transform: translateX(5px);
  }
  //Laptop
  @media (max-width: 1900px) {
  }
  @media (max-width: 1439px) {
    .explore__link {
      top: 150px;
    }
  }
  //Ipad
  @media (max-width: 1279px) {
    .explore__link {
      top: 100px;
      width: 120px;
      height: 130px;
    }
    .explore__link-text span {
      font-size: 22px;
    }
    .explore__link-icon svg {
      height: 60px;
      width: 60px;
    }
  }
  //Mobile
  @media (max-width: 767px) {
    .explore__link {
      top: 15px;
      width: 65px;
      height: 60px;
    }
    .explore__link-icon {
      margin-bottom: 0px;
    }
    .explore__link-icon svg {
      height: 20px!important;
      width: 20px!important;
    }
    .explore__link-text span {
      font-size: 14px;
      line-height: 15px;
    }
  }
</style>
