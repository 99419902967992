<template>
   <div class="slider-wrap" :class="{'with-margin': contentData.conf.conf.includes('with_margin')}">
      <ExplorerSec />
      <template v-if="$get(contentData, 'data.listCount')">

         <div v-if="$get(contentData, 'data.list').length > 1" class="slider-prev">
            <svg width="42" height="76" viewBox="0 0 42 76" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M39.4297 2.57031L4 38L39.4297 73.4297" stroke="white" stroke-width="5" />
            </svg>
         </div>
         <div v-if="$get(contentData, 'data.list').length > 1" class="slider-next">
            <svg width="42" height="76" viewBox="0 0 42 76" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M2.42969 73.4297L37.8594 38L2.42969 2.57031" stroke="white" stroke-width="5" />
            </svg>
         </div>

         <swiper :slides-per-view="1" :speed="1200" :modules="modules" :freeMode="true" :watchOverflow="true"
            :pagination="{ clickable: true, dynamicBullets: true, dynamicMainBullets: 4 }" :navigation="{
               nextEl: '.slider-next',
               prevEl: '.slider-prev',
            }" class="main-slider" :effect="'creative'" :creativeEffect="{
   prev: {
      translate: ['-40%', 0, -1],
   },
   next: {
      translate: ['100%', 0, 0],
   },
}" :autoplay="{
   delay: 50000,
}" :loop="$get(contentData, 'data.list').length > 1">
            <swiper-slide v-for="item in $get(contentData, 'data.list')" :key="item" class="slide">
               <component :is="$get(item, 'link.url') ? 'router-link' : 'div'" :to="$get(item, 'link.url') || ''">
                  <div class="img-box" :class="{'not-full': contentData.conf.conf.includes('not_full')}">
                     <picture>
                        <source media="(max-width: 767px)" :srcset="$get(item, 'mobile_image.0.devices.desktop')" />
                        <source media="(max-width: 1023px)" :srcset="$get(item, 'image.0.devices.tablet')" />
                        <img :src="$get(item, 'image.0.devices.desktop')" :alt="$get(item, 'image.0.title')" />
                     </picture>
                     <div class="layer"></div>
                  </div>
                  <div class="content">
                     <span class="sub-ttl">
                        {{ $get(item, 'additionat_info') }}
                     </span>
                     <h2 class="title">
                        {{ $get(item, 'title') }}
                     </h2>
                     <p class="teaser">
                        {{ $get(item, 'teaser') }}
                     </p>
                  </div>
               </component>
            </swiper-slide>
         </swiper>
      </template>
   </div>
</template>
<script>

import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Pagination, Navigation, EffectCreative, Autoplay } from 'swiper';
import ExplorerSec from '../../UI/ExplorerSec.vue';



export default {
   name: 'main_slider',
   props: ['contentData'],
   components: {
      Swiper,
      SwiperSlide,
      ExplorerSec
   },
   setup() {
      return {
         modules: [Pagination, Navigation, EffectCreative, Autoplay],
      };
   },
};
</script>
<style scoped lang="scss">
// add with-margin class to slider-wrap for adding margin
// add not-full class to img-box to disable full height behavior
.slider-wrap {
   position: relative;
}
.slider-wrap.with-margin{
   margin-top: 144px;
}
.slider-wrap.full{
   height: 100vh;
}

::v-deep.swiper-slide {
   cursor: pointer;
}

.img-box {
   position: relative;
   width: 100%;
   height: calc(100vh - 75px);
   min-height: 515px;

   &.not-full{
      padding-top: 60.1%;
      height: unset !important;
   }

   img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

   }

   .layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
   }
}

.slider-prev {
   position: absolute;
   top: 50%;
   left: 85px;
   transform: translateY(-50%);
   z-index: 2;
   cursor: pointer;
   transition: .4s;

   &:hover {
      transform: translate(-10px, -50%);
   }

}

.slider-next {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   right: 85px;
   z-index: 2;
   cursor: pointer;
   transition: .4s;

   &:hover {
      transform: translate(10px, -50%);
   }
}

::v-deep .swiper-pagination {
   position: absolute;
   display: flex;
   bottom: 58px;
   left: 50%;
   transform: translateX(-50%);
   z-index: 2;
   justify-content: center;

   &.swiper-pagination-lock {
      display: none;
   }


   .swiper-pagination-bullet {
      display: block;
      width: 96px;
      height: 6px;
      background-color: rgba(255, 255, 255, 0.5);
      transition: .4s;
      margin-right: 7px;
      cursor: pointer;
      display: none;
   }

   .swiper-pagination-bullet-active-main,
   .swiper-pagination-bullet-active-next,
   .swiper-pagination-bullet-active-prev {
      flex: none;
      display: block;
   }

   .swiper-pagination-bullet:last-child {
      margin-right: 0;
   }

   .swiper-pagination-bullet-active,
   .swiper-pagination-bullet:hover {
      background-color: rgba(255, 255, 255, 1);
   }
}

.content {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   padding: 71px 320px 124px;

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   text-align: center;

   * {
      color: #fff;
   }

   .sub-ttl,
   .teaser {
      font-size: 34px;
      line-height: 40px;
      max-width: 700px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      transition: transform .62s .1s cubic-bezier(0.34, 0, 0.18, 1);
   }

   .teaser:hover {
      transform: scale(0.9);
   }

   .title {
      font-size: 89px;
      line-height: 116px;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      white-space: pre-line;
      will-change: transform;

      transition: transform .62s .1s cubic-bezier(0.34, 0, 0.18, 1);
   }

   .title:hover {
      transform: scale(0.9);
   }
}



@media screen and (max-width: 1900px) {
   .slider-wrap.with-margin {
      margin-top: 199px;
   }

   .img-box.not-full {
      padding-top: 59.95%;
   }

   .slider-prev {
      left: 34px;

      svg {
         display: block;
         width: 38px;
         height: 57px;
      }
   }

   .slider-next {
      right: 34px;

      svg {
         display: block;
         width: 38px;
         height: 57px;
      }
   }

   ::v-deep .swiper-pagination {
      bottom: 43px;

      .swiper-pagination-bullet {
         width: 72px;
         height: 5px;
      }
   }

   .content {
      padding: 54px 120px 93px;

      .sub-ttl,
      .teaser {
         max-width: 720px;
      }
   }

}

@media screen and (max-width: 1439px) {
   .slider-wrap.with-margin {
      margin-top: 155px;
   }

   .img-box.not-full.not-full {
      padding-top: 59.95%;
   }

   .slider-prev {
      left: 30px;

      svg {
         display: block;
         width: 32px;
         height: 48px;
      }
   }

   .slider-next {
      right: 30px;

      svg {
         display: block;
         width: 32px;
         height: 48px;
      }
   }

   ::v-deep .swiper-pagination {
      bottom: 38px;

      .swiper-pagination-bullet {
         width: 64px;
         height: 4px;
      }
   }

   .content {
      padding: 48px 120px 82px;

      .title {
         font-size: 96px;
         line-height: 116px;
         -webkit-line-clamp: 3;
      }

      .sub-ttl,
      .teaser {
         max-width: 640px;
      }
   }

}

@media screen and (max-width: 1279px) {
   .slider-wrap.with-margin {
      margin-top: 105px;
   }

   .img-box.not-full.not-full {
      padding-top: 59.95%;
   }

   .slider-prev {
      left: 18px;

      svg {
         display: block;
         width: 20px;
         height: 30px;
      }
   }

   .slider-next {
      right: 18px;

      svg {
         display: block;
         width: 20px;
         height: 30px;
      }
   }

   ::v-deep .swiper-pagination {
      bottom: 23px;

      .swiper-pagination-bullet {
         width: 38px;
         height: 2px;
      }
   }

   .content {
      padding: 28px 120px 49px;

      .title {
         font-size: 48px;
         line-height: 58px;
         -webkit-line-clamp: 3;
      }

      .sub-ttl,
      .teaser {
         max-width: 370px;
         font-size: 18px;
         line-height: 22px;
      }
   }

}
@media screen and (max-width: 1023px){
   .img-box {
      height: calc(100vh - 65px);
   }
}
@media screen and (max-width: 767px) {
   .slider-wrap.with-margin {
      margin-top: 20px;
   }

   .img-box{
      min-height: unset;
   }
   .img-box.not-full {
      padding-top: 0;
      height: 633px;
   }

   .slider-prev {
      left: 11px;

      svg {
         display: block;
         width: 24px;
         height: 36px;
      }
   }

   .slider-next {
      right: 11px;

      svg {
         display: block;
         width: 24px;
         height: 36px;
      }
   }

   ::v-deep .swiper-pagination {
      bottom: 38px;

      .swiper-pagination-bullet {
         width: 64px;
         height: 4px;
      }
   }

   .content {
      padding: 55px 60px 62px;

      .title {
         font-size: 34px;
         line-height: 40px;
         -webkit-line-clamp: 4;
      }

      .sub-ttl,
      .teaser {
         font-size: 16px;
         line-height: 19px;
         max-width: 250px;
      }
   }

}

@media screen and (max-width: 384px) {
   .content {
      padding: 55px 60px 62px;

      .title {
         font-size: 21px;
         line-height: 30px;
         -webkit-line-clamp: 4;
      }
   }

   ::v-deep .swiper-pagination {
      .swiper-pagination-bullet {
         width: 34px;
         height: 2px;
      }
   }
}
</style>
